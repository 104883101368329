<template>
  <Footer
    :copyright="copyright"
    :socials="socials"
    :links="links"
    :locales="languages"
    :currentLocale="currentLocale"
    :feedbackText="data.website_feedback"
    :labelPlatformUsing="data.labelPlatformUsing"
    :labelFreeUse="data.labelFreeUse"
    :mobileApp="{ href: null }"
    compact
    @feedback="openFeedbackForm"
    @changeLang="changeLang"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import Footer from '@rr-component-library/footer-basic/src/main';

export default {
  name: 'FooterRR',
  components: {
    Footer,
  },

  computed: {
    ...mapGetters('settings', ['theme', 'themeSettings', 'userbackAvailable']),
    data() {
      try {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        const themeData = require(`./themes/${this.theme}/data.json`);
        return themeData[this.$lang] || themeData.ru || {};
      } catch {
        throw Error(`Footer component data is not defined for the ${this.theme} theme`);
      }
    },
    copyright() {
      return this.data.copyright || '';
    },
    socials() {
      return this.data.socials || [];
    },

    links() {
      return this.data.links || [];
    },
    languages() {
      return this.data?.locales || ['ru', 'en', 'uz'].filter((lang) => (this.themeSettings.languages || []).includes(lang));
    },
    currentLocale() {
      return this.languages.find((lang) => (lang === this.$lang));
    },
  },
  methods: {
    changeLang(lang) {
      setCulture(lang);
    },
    openFeedbackForm() {
      if (window.Userback) {
        window.Userback.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
